<template>
  <v-card
    v-if="!configGetter($rttI18n.tenantId, getThemeId).disable5StarRating"
    class="pa-4 mb-6"
    :class="
      !ratingData &&
      !sent &&
      !showTrustPilotLink(trackingData) &&
      this.$rttI18n.isGlsItSelected
        ? 'nps'
        : showTrustPilotLink(trackingData)
          ? 'trustpilot'
          : ''
    "
  >
    <div
      v-if="!ratingData && !sent && !showTrustPilotLink(trackingData)"
      class="feedback pa-0 mx-0 v-col-12"
    >
      <div v-if="this.$rttI18n.isGlsItSelected">
        <NPSComponent />
      </div>
      <div v-else>
        <p class="header font-weight-bold text-center pa-0">
          {{ $t('feedback.header') }}
        </p>
        <v-form ref="ratingForm" v-model="valid" :lazy-validation="lazy">
          <div class="text-center">
            <button
              v-for="index in stars"
              :key="index"
              v-on:click.prevent="setRating(index)"
              v-on:mouseover="hoverStars(index)"
              v-on:mouseout="hoverStars(0)"
              :style="getStyle(index)"
              class="star"
            />
          </div>
          <div v-if="rating" class="mt-4">
            <v-textarea
              bg-color="#fff"
              class="message"
              :counter="messageLength"
              :label="$t('feedback.messageLabel')"
              no-resize
              variant="outlined"
              :placeholder="$t('feedback.messagePlaceholder')"
              :rules="messageRules"
              v-model="message"
            ></v-textarea>
            <v-btn
              :block="true"
              type="submit"
              :disabled="!valid"
              v-on:click="addRatingWrapper"
              class="bg-primary"
            >
              {{ $t('generic.send') }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
    <div
      v-else-if="ratingData && !sent"
      v-on:click="showMessage = !showMessage"
      class="feedback text-center pa-4 mx-0 v-col-12"
    >
      <p class="header font-weight-bold pa-0">
        {{ $t('feedback.headerRated') }}
      </p>
      <div class="text-center mb-4">
        <button
          v-for="index in stars"
          :key="index"
          :style="getStyle(index)"
          class="star"
        />
      </div>
      <div v-if="showMessage && ratingData.message">
        <p class="header font-weight-bold pa-0">
          {{ $t('feedback.headerRatedComment') }}
        </p>
        <p>
          {{ this.ratingData.message }}
        </p>
      </div>
    </div>
    <div v-else-if="sent" class="feedback text-center pa-2 mx-0 v-col-12">
      <p class="header font-weight-bold pa-0">
        {{ $t('feedback.headerSent') }}
      </p>
    </div>
    <div v-if="showTrustPilotLink(trackingData)" class="trustpilot-content">
      <img class="" alt="Driver" src="/trustpilot_image.png" />
      <h2>
        {{ $t('feedback.trustPilot.content') }}
      </h2>
      <p class="font-weight-bold">
        {{ $t('feedback.header') }}&nbsp;
        <a href="" v-on:click.prevent="openTrustPilotDialog">{{
          $t('feedback.trustPilot.button')
        }}</a>
      </p>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { configGetter } from '@/config/configGetter';
import { showTrustPilotLink } from '@/services/trustPilot';
import publicPath from '@/util/publicPath';
import { getUtmSource } from '@/router/queryParam.js';
import NPSComponent from '@/components/NPSComponent.vue';
import { sendAnalytics } from '@/services/analytics.js';
import { TOGGLE_DIALOG_EVENT } from '@/store/constants.js';
import { dialogs } from '@/components/dialogs/dialogs.js';

export default {
  name: 'FeedbackComponent',
  components: {
    NPSComponent
  },
  props: {
    postcode: {
      type: String,
      default: null
    },
    ratingData: {
      type: Object,
      default: null
    },
    trackingId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hover: 0,
      lazy: false,
      message: this.ratingData?.message || '',
      messageLength: 256,
      messageRules: [
        v =>
          v.length <= this.messageLength ||
          this.$t('feedback.messageExceededLength')
      ],
      rating: this.ratingData?.stars || 0,
      sent: false,
      showMessage: !!this.ratingData?.message || false,
      stars: 5,
      valid: true,
      configGetter,
      showTrustPilotLink
    };
  },
  computed: {
    ...mapGetters(['getThemeId']),
    ...mapState(['trackingData'])
  },
  methods: {
    ...mapActions(['addRating']),
    addRatingWrapper() {
      this.addRating({
        utmSource: getUtmSource(this.$route),
        tenantId: this.$rttI18n.tenantId,
        postcode: this.postcode,
        trackingId: this.trackingId,
        body: {
          message: this.message,
          stars: this.rating
        }
      });
      this.sent = true;
    },
    hoverStars(rating) {
      this.hover = rating;
    },
    setRating(rating) {
      if (this.rating === rating) {
        this.rating = 0;
        this.hover = 0;
      } else {
        this.rating = rating;
      }
    },
    getStyle(index) {
      return `background-size: contain; background-image: url(${publicPath(
        `/themes/${this.getThemeId}/feedback/star-${
          this.hover >= index || this.rating >= index ? 'active' : 'inactive'
        }.svg`
      )})`;
    },
    openTrustPilotDialog() {
      sendAnalytics(this.trackingData, 'clicksStats', 'trustPilot');
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, dialogs.TRUST_PILOT_CONSENT_DIALOG);
    }
  }
};
</script>

<style>
.header {
  font-size: 14px;
}

.feedback .message fieldset {
  border: 0.5px solid rgba(28, 35, 49, 0.3);
  border-radius: 0;
}
.feedback .star {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.nps {
  background-color: #d8e5ef;
}
.trustpilot {
  background: #cce7bc;
}
.trustpilot-content {
  margin-left: 1em;
  margin-right: 1em;
}
.trustpilot h2 {
  margin-bottom: 0.5em;
}
.trustpilot-content img {
  float: right;
  height: 120px;
  margin-left: 1em;
}
.trustpilot-content p {
  font-size: 14px;
}
</style>
