import { getCountry, getDomain, getUtmSource } from '@/router/queryParam';
import { configPromises } from './themeState';

export const defaultThemeName = () =>
  getDomain() === 'rtt.bettermile.com' ? 'bettermile' : 'gls';
export const whiteListedThemeIds = [
  'bmdemo-global',
  'bvb',
  'gls',
  'gls-it',
  'gls-one.de',
  'royalmail-uk'
];
const themeMapping = {
  'bmdemo-global': 'bettermile',
  'gls-one.de': 'gls-one'
};

const countryToThemeMapping = {
  IT: 'gls-it'
};

export const getThemeIdFromApiResponse = trackingData => {
  const fromApi = trackingData.shipperId || trackingData.tenantId;
  return getThemeIdFrom(fromApi);
};

export const getThemeIdFrom = utmSource =>
  whiteListedThemeIds.includes(utmSource) &&
  (themeMapping[utmSource] || utmSource);

export const getThemeIdFromCountry = country => countryToThemeMapping[country];

export const getThemeIdFromStateAndRoute = (state, route) => {
  return (
    getThemeIdFrom(getUtmSource(route)) ||
    getThemeIdFromApiResponse(state.trackingData) ||
    getThemeIdFromCountry(getCountry(route))
  );
};

export const getThemeIdFromRoute = route =>
  getThemeIdFrom(getUtmSource(route)) ||
  getThemeIdFromCountry(getCountry(route));

export default async function loadConfigByThemeId(themeId) {
  if (configPromises[themeId]) return configPromises[themeId];
  const configPromise = _loadConfigByThemeId(themeId);
  configPromises[themeId] = configPromise;
  return configPromise;
}

async function _loadConfigByThemeId(themeId) {
  if (!themeId) return { slots: [] };
  const config = await import(
    /* webpackChunkName: "[request]" */ `./themes/${themeId}.js`
  );

  if (!config) {
    // eslint-disable-next-line no-console
    console.error(`${themeId} has no config. Please provide a correct themeId`);
    return { slots: [] };
  }
  return config.default;
}
